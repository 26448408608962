<template>
  <div>
    <div class="absolute">
      <h-tip-dialog
        v-model="showTip"
        v-bind:label="label"
        v-bind:tip="tip"
      ></h-tip-dialog>
    </div>
    <v-textarea
      v-bind:value="value"
      v-on:input="$emit('input', $event)"
      append-icon="mdi-information-outline"
      outlined
      no-resize
      class="h-text-area"
      :label="label"
      :counter="counter"
      :maxlength="maxlength"
      :placeholder="placeholder"
      @click:append="enableTip()"
    >
    </v-textarea>
  </div>
</template>

<script>
import VTextArea from "vuetify/lib/components/VTextarea";
import HTipDialog from "./HTipDialog";

export default {
  components: { HTipDialog },
  name: "HTextArea",
  extends: VTextArea,
  props: ["tip", "label", "value", "counter", "maxlength", "placeholder"],
  methods: {
    enableTip() {
      this.showTip = !this.showTip;
    }
  },
  data: () => ({
    showTip: false
  })
};
</script>

<style>
.h-text-area .v-text-field__slot > label {
  font-size: 0.9rem;
}
</style>
