<template>
    <div>
    <h-tip-dialog v-model="showTip" v-bind:label="label || altlabel || title" v-bind:tip="tip"></h-tip-dialog>
            <v-text-field 
                class="moneyInput"
                append-icon="mdi-information-outline" 
                @click:append="enableTip()"                              
                v-model="cmpValue"
                v-bind:label="label"
                v-bind="properties"
                v-bind:maxlength="options.length + options.precision"
                v-on:keypress="keyPress"
                v-on:blur="$emit('blur')"
                v-on:change="$emit('change')"
                v-on:click="$emit('click')"
                v-on:focus="$emit('focus')"
                v-on:keydown="$emit('keydown')"
                v-on:mousedown="$emit('mousedown')"
                v-on:mouseup="$emit('mouseup')"
                ref="ref"                
                >
            </v-text-field>
    </div>
</template>

<style scoped>
  .moneyInput .v-text-field__details {
    display:none !important;
  }
  .moneyInput > div.v-text-field__details {
    display:none !important;
  }
  .moneyInput {
    position:absolute;
    top:5px;
    right:5px;
  }
</style>

<script>
import VTextField from 'vuetify/lib/components/VTextField'
import HTipDialog from './HTipDialog'

export default {
  extends: VTextField,
  components: { HTipDialog },
  model: { prop: "value", event: "input" },
  props: {
    tip: {
      type: [String]
    },
    altlabel: {
      type: [String],
      default: "Info"
    },
    title: {
      type: [String],
      default: "Info"
    },
    value: {
      type: [String, Number],
      default: "0",
    },
    label: {
      type: String,
      default: "",
    },
    properties: {
      type: Object,
      default: function() {
        return {};
      },
    },
    options: {
      type: Object,
      default: function() {
        return {
          locale: "pt-BR",
          length: 9,
          precision: 0,
          empty: null,
        };
      },
    },
  },
  data: () => ({
      showTip: false
  }),
  /*
   v-model="cmpValue": Dessa forma, ao digitar, o valor é atualizado automaticamente no componente pai.
   O valor digitado entra pelo newValue do Set é emitido para o componente pai, retorna pelo get e pára.
  */
  computed: {
    cmpValue: {
      get: function() {
        return this.humanFormat(this.value);
      },
      set: function(newValue) {
        this.$emit("input", this.machineFormat(newValue));
      },
    },
  },
  watch: {},
  methods: {
    enableTip() {
        this.showTip = !this.showTip
    },      
    humanFormat: function(value) {
      if (value) {
        value = Number(value).toLocaleString(this.options.locale, {
          maximumFractionDigits: this.options.precision,
          minimumFractionDigits: this.options.precision,
        });
      } else {
        value = this.options.empty;
      }
      return value;
    },

    machineFormat(value) {
      if (value) {
        value = this.clearNumber(value);
        // Ajustar quantidade de zeros à esquerda
        value = value.padStart(parseInt(this.options.precision) + 1, "0");
        // Incluir ponto na casa correta, conforme a precisão configurada
        value =
          value.substring(0, value.length - parseInt(this.options.precision)) +
          "." +
          value.substring(
            value.length - parseInt(this.options.precision),
            value.length
          );
        if (value === "") {
          value = this.options.empty;
        }
      } else {
        value = this.options.empty;
      }
      return value;
    },

    // Retira todos os caracteres não numéricos e zeros à esquerda
    clearNumber: function(value) {
      let result = "";
      if (value) {
        let flag = false;
        let arrayValue = value.toString().split("");
        for (var i = 0; i < arrayValue.length; i++) {
          if (this.isInteger(arrayValue[i])) {
            if (!flag) {
              // Retirar zeros à esquerda
              if (arrayValue[i] !== "0") {
                result = result + arrayValue[i];
                flag = true;
              } else {
                // Permitir zero quando valor igual a zero - Tipo 3 (Money or Percent)
                if (Number(value) === 0) {
                  result = result + arrayValue[i];
                }
              }
            } else {
              result = result + arrayValue[i];
            }
          }
        }
      }
      return result;
    },

    keyPress($event) {
      // console.log($event.keyCode); //keyCodes value
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      // if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
      if (keyCode < 48 || keyCode > 57) {
        // 46 is dot
        $event.preventDefault();
      }
    },

    isInteger(value) {
      let result = false;
      if (Number.isInteger(parseInt(value))) {
        result = true;
      }
      return result;
    },

    focus() {
      setTimeout(() => {
        this.$refs.ref.focus();
      }, 500);
    },
    
  },
};
</script>
